<script lang="ts" setup>
const { refreshKey } = useRefresh();
</script>

<template>
  <div class="layout-empty">
    <main>
      <slot :key="refreshKey" />
    </main>
  </div>
</template>

<style lang="postcss" scoped>
.layout-empty {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
</style>
